export default {
    en: {
        buttons: {
            save: "Save",
            // ...
        }
    },
    ja: {
        buttons: {
            save: "保存",
            // ...
        }
    }
}